import { Component, Input } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Constants } from '../constants';
import { Child } from '../models/Child';
import { Duty } from '../models/Duty';
import { DutyId } from '../models/DutyId';
import { FormDuty } from '../models/FormDuty';
import { Volunteer } from '../models/Volunteer';
import { VolunteerService } from '../services/volunteer.service';
import { ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FormCheckboxInputComponent } from '../form-checkbox-input/form-checkbox-input.component';
import { NgFor, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormDateInputComponent } from '../form-date-input/form-date-input.component';
import { FormNumberInputComponent } from '../form-number-input/form-number-input.component';
import { FormTextInputComponent } from '../form-text-input/form-text-input.component';

@Component({
    selector: 'app-volunteer-sign-up',
    templateUrl: './volunteer-sign-up.component.html',
    styleUrls: ['./volunteer-sign-up.component.scss'],
    standalone: true,
    imports: [FormTextInputComponent, FormNumberInputComponent, FormDateInputComponent, MatFormFieldModule, NgFor, FormCheckboxInputComponent, NgIf, MatSelectModule, FormsModule, MatOptionModule, MatInputModule, MatButtonModule]
})
export class VolunteerSignUpComponent {
  @Input()
  volunteer: Volunteer = new Volunteer()
  @Input()
  anzahlKinder: number = 1
  @Input()
  alterKinder: number[] = []
  brauchtBetreuung: boolean = false
  constants: Constants = new Constants()
  sizes: string[] = this.constants.sizes
  wantShirt: boolean = false
  childCare: boolean = false
  numberOfChildren: number = 0
  childrenAges: number[] = []
  specialLeave: boolean = false
  agb: boolean = false
  submitSuccess = false
  submitFailure = false
  isLeader = false

  formDuties: FormDuty[] = []
  zipMinValue: number = 10000
  zipMaxValue: number = 99999

  constructor(private service: VolunteerService, private route: ActivatedRoute) {
    this.loadDuties()
    route.queryParams
      .subscribe(params => {
        if (params["role"] == 'bereichsleiter') this.isLeader = true
      }
      );
  }

  async loadDuties() {
    this.service.getDuties().subscribe(resp => { this.formDuties = this.getFormDuties(resp) })
  }

  createVolunteer() {
    if (this.volunteer.isValid()) {
      this.agb = false
      this.volunteer.isLeader = this.isLeader
      this.volunteer.duties = this.getAcceptedDuties(this.formDuties)
      if (this.childCare) {
        for (let i = 0; i < this.childrenAges.length; i++) {
          this.volunteer.children[i] = new Child(this.childrenAges[i])
        }
      }
      if (!this.wantShirt) {
        this.volunteer.tshirtCount = 0
        this.volunteer.tshirtSize = ""
        this.volunteer.tshirtBacksideText = ""
        this.volunteer.tshirtFit = 0
      }
      if (!this.specialLeave) {
        this.volunteer.contactEmployer = ""
        this.volunteer.nameEmployer = ""
        this.volunteer.addressEmployer = ""
      }
      this.service.create(this.volunteer).pipe(
        catchError(error => {
          this.submitFailure = true
          return throwError(() => new Error('Something went wrong!'));
        })
      )
        .subscribe(response => {
          this.submitSuccess = true
          this.submitFailure = false
          this.agb = false
        });
      if (this.specialLeave) {
        this.service.sendSpecialLeaveMail(this.volunteer).subscribe()
      }
    }
    else {
      this.submitFailure = true
      this.agb = false
    }
  }

  getFormDuties(duties: Duty[]): FormDuty[] {
    let formDuties: FormDuty[] = []
    duties.forEach(duty => { formDuties.push(new FormDuty(duty.id, duty.name, false)) })
    return formDuties
  }

  getDutyIds(formDuties: FormDuty[]): DutyId[] {
    let dutyIds: DutyId[] = []
    formDuties.forEach(formDuty => { if (formDuty.accepted) dutyIds.push(formDuty.getDutyId()) })
    return dutyIds
  }
  getAcceptedDuties(formDuties: FormDuty[]): Duty[] {
    let duties: Duty[] = []
    formDuties.forEach(duty => { if (duty.accepted) duties.push(new Duty(duty.id, duty.name)) })
    return duties
  }

  trimChildren() {
    this.childrenAges = this.childrenAges.slice(0, this.numberOfChildren)
  }
}