import { Child } from "./Child"
import { DutyId } from "./DutyId"

export class Volunteer {
    name: string = ''
    surname: string = ''
    street: string = ''
    number: string = ''
    postcode: number = 0
    city: string = ''
    birthdate: string = ''
    phoneNumber: string = ''
    mobileNumber: string = ''
    email: string = ''
    community: string = ''
    duties: DutyId[] = []
    otherJobs: string = ''
    vegetarian: boolean = false
    photoConsent: boolean = false
    firstTime: boolean = false
    trained: boolean = false

    tshirtFit: number = 0
    tshirtSize: string = ''
    tshirtCount: number = 0
    tshirtBacksideText: string = ''

    contactEmployer: string = ''
    nameEmployer: string = ''
    addressEmployer: string = ''

    isLeader: boolean = false

    children: Child[] = []

    comment: string = ''

    isValid(): boolean {
        return this.name.length != 0 && this.surname.length != 0 && this.street.length != 0 && this.number.length != 0 && this.postcode != 0 && this.city.length != 0 && this.birthdate != null && this.birthdate.length != 0 && (this.phoneNumber.length != 0 || this.mobileNumber.length != 0) && this.email.length != 0
    }
}
