import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-form-number-input',
    templateUrl: './form-number-input.component.html',
    styleUrls: ['./form-number-input.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, FormsModule, MatInputModule]
})
export class FormNumberInputComponent implements OnInit {

  @Input()
  idName: string = ''
  @Input()
  labelName: string = ''
  @Input()
  model: number = 0
  @Input()
  minValue: number = 0
  @Input()
  maxValue: number = Number.MAX_SAFE_INTEGER
  @Input()
  defaultValue: number = 0
  @Input()
  required: boolean = false
  @Output()
  modelChange = new EventEmitter<number>();


  constructor() { }

  ngOnInit(): void {
  }

  change() {
    this.modelChange.emit(this.model)
  }

}
