import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import { VolSignUpComponent } from './vol-sign-up/vol-sign-up.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

const routes: Routes = [
  { path: '', component: WelcomePageComponent, title: "Mitarbeiterbereich KiFeWo" },
  { path: 'anmeldung', component: VolSignUpComponent, title: "Mitarbeiteranmeldung KiFeWo" },
  { path: 'calendar', component: CalendarComponent, title: "Mitarbeitertermine KiFeWo" },
  { path: '**', component: WelcomePageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule { }
