<app-page-title title="Mitarbeiteranmeldung"></app-page-title>
<div class="body">
  <div class="infoText" *ngIf="enabled">
    <h2>
      Super, dass Du als Mitarbeiter bei der KiFeWo oder den TET dabei bist.
    </h2>
    <p>
      Hier kannst Du Dich als Mitarbeiter anmelden. Fülle zur Anmeldung bitte
      das Formular aus, Du bekommst bei erfolgreicher Anmeldung eine E-Mail
      zugeschickt.
    </p>
    <p>
      Den
      <a href="https://kifewo.de/files/Mitarbeitergrundsaetze.pdf"
        >verbindlichen Mitarbeiter-Grundsätzen und Hinweisen</a
      >
      stimmst Du mit Deiner Anmeldung zu. Bitte lies sie Dir daher vor Deiner
      Anmeldung durch, vielen Dank.
    </p>
    <p>
      Die
      <a href="/calendar">KiFeWo-Termine</a>
      solltest Du Dir ebenfalls durchlesen und möglichst gleich im Kalender
      speichern und bei Bedarf ausdrucken.
    </p>
    <h2>Hier können die beiden Dokumente heruntergeladen werden:</h2>
    <a href="https://kifewo.de/files/Mitarbeitergrundsaetze.pdf"
      >Verbindliche Mitarbeiter-Grundsätze und Hinweise</a
    >
    <a href="/calendar">Termine für Mitarbeiter</a>
  </div>
  <div *ngIf="!enabled">
    <p>Die Anmeldung ist vorübergehend geschlossen.</p>
  </div>
  <app-volunteer-sign-up *ngIf="enabled"></app-volunteer-sign-up>
</div>
