import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Volunteer } from '../models/Volunteer'
import { Duty } from '../models/Duty'
import { environment } from '../../environments/environment';

const baseUrl = environment.api_url
const volunteerUrl = baseUrl + 'volunteers'
const dutiesUrl = baseUrl + 'duties'
const mailUrl = baseUrl + 'mail'
const specialLeaveUrl = mailUrl + '/specialleave'
const leaderUrl = mailUrl + '/leader'

@Injectable({
    providedIn: 'root'
})
export class VolunteerService {

    constructor(private http: HttpClient) { }

    create(data: Volunteer): Observable<any> {
        console.log(data)
        return this.http.post(volunteerUrl, data)
    }

    getDuties(): Observable<Duty[]> {
        return this.http.get<Duty[]>(dutiesUrl)
    }

    sendSpecialLeaveMail(data: Volunteer): Observable<any> {
        return this.http.post(specialLeaveUrl, data)
    }
}
