<div class="body">
  <form
    ngNoForm
    onsubmit="return false"
    class="volunteer-form"
    (keydown.enter)="$event.preventDefault()"
  >
    <app-form-text-input
      idName="firstName"
      labelName="Vorname"
      [(model)]="volunteer.name"
      [required]="true"
    ></app-form-text-input>
    <app-form-text-input
      idName="lastName"
      labelName="Nachname"
      [(model)]="volunteer.surname"
      [required]="true"
    ></app-form-text-input>
    <app-form-text-input
      idName="street"
      labelName="Straße"
      [(model)]="volunteer.street"
      [required]="true"
    ></app-form-text-input>
    <app-form-text-input
      idName="houseNumber"
      labelName="Hausnummer"
      [(model)]="volunteer.number"
      [required]="true"
    ></app-form-text-input>
    <app-form-number-input
      idName="zip"
      labelName="Postleitzahl"
      [minValue]="zipMinValue"
      [maxValue]="zipMaxValue"
      [(model)]="volunteer.postcode"
      [required]="true"
    ></app-form-number-input>
    <app-form-text-input
      idName="location"
      labelName="Wohnort"
      [(model)]="volunteer.city"
      [required]="true"
    ></app-form-text-input>
    <app-form-date-input
      idName="dateOfBirth"
      labelName="Geburtsdatum"
      [(model)]="volunteer.birthdate"
      [required]="true"
    ></app-form-date-input>
    <app-form-text-input
      idName="mobileNumber"
      labelName="Handynummer"
      [(model)]="volunteer.mobileNumber"
      [required]="volunteer.phoneNumber.length == 0"
    ></app-form-text-input>
    <app-form-text-input
      idName="phoneNumber"
      labelName="Telefonnummer"
      [(model)]="volunteer.phoneNumber"
      [required]="volunteer.mobileNumber.length == 0"
    ></app-form-text-input>
    <app-form-text-input
      idName="mail"
      labelName="E-Mail"
      [(model)]="volunteer.email"
      [required]="true"
    ></app-form-text-input>
    <app-form-text-input
      idName="church"
      labelName="In welche Gemeinde gehst du normalerweise?"
      [(model)]="volunteer.community"
      [required]="false"
    ></app-form-text-input>
    <mat-label for="duties"
      ><h3 id="dutiesLabel">Aufgabenbereiche</h3></mat-label
    >
    <div class="duties">
      <div id="duty" *ngFor="let formDuty of formDuties; let i = index">
        <app-form-checkbox-input
          idName="duty {{ i }}"
          labelName="{{ formDuty.name }}"
          [(model)]="formDuties[i].accepted"
        ></app-form-checkbox-input>
      </div>
    </div>
    <app-form-text-input
      idName="otherJobs"
      labelName="Sonstige Aufgaben"
      [(model)]="volunteer.otherJobs"
    ></app-form-text-input>
    <app-form-checkbox-input
      idName="veggie"
      labelName="Vegetarier"
      [(model)]="volunteer.vegetarian"
    ></app-form-checkbox-input>
    <app-form-checkbox-input
      idName="foto"
      labelName="Ich darf von den beauftragten Mitarbeitern fotografiert oder gefilmt werden. Diese Aufnahmen dürfen über die Webseite, im Flyer oder bei Instagram veröffentlicht werden. Bei Gruppenfotos (als solche) dürfen die Familien der Teilnehmer das Foto bekommen, auf dem ich auch abgebildet bin."
      [(model)]="volunteer.photoConsent"
    ></app-form-checkbox-input>
    <app-form-checkbox-input
      idName="firstTime"
      labelName="Ich bin das erste Mal Mitarbeiter bei der KiFeWo"
      [(model)]="volunteer.firstTime"
    ></app-form-checkbox-input>
    <app-form-checkbox-input
      idName="training"
      labelName="Ich habe bei der Schulung 'Prävention vor sexualisierter Gewalt' bereits teilgenommen"
      [(model)]="volunteer.trained"
    ></app-form-checkbox-input>
    <div>
      <app-form-checkbox-input
        idName="wantShirt"
        labelName="Mitarbeiter T-Shirt (Für Kleingruppenmitarbeiter Pflicht). Kosten: 10€ pro T-Shirt"
        [(model)]="wantShirt"
      ></app-form-checkbox-input>
      <div class="advancedOptionsField" *ngIf="wantShirt">
        <div class="schnitt">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Schnitt T-Shirt</mat-label>
            <mat-select
              aria-labelledby="schnitt"
              [(ngModel)]="volunteer.tshirtFit"
              [required]="wantShirt"
            >
              <mat-option [value]="0">Mann</mat-option>
              <mat-option [value]="1">Frau</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="size">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Größe T-Shirt</mat-label>
            <mat-select
              [(ngModel)]="volunteer.tshirtSize"
              [required]="wantShirt"
            >
              <mat-option *ngFor="let size of sizes" [value]="size">
                {{ size }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="quantity">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Anzahl T-Shirts</mat-label>
            <mat-select
              [(ngModel)]="volunteer.tshirtCount"
              [required]="wantShirt"
            >
              <mat-option
                *ngFor="let quantity of constants.quantitys"
                [value]="quantity"
              >
                {{ quantity }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="nameShirt">
          <app-form-text-input
            idName="nameShirt"
            labelName="Name auf der Rückseite"
            [(model)]="volunteer.tshirtBacksideText"
            [required]="wantShirt"
          ></app-form-text-input>
        </div>
      </div>
    </div>
    <div class="child">
      <app-form-checkbox-input
        idName="childCare"
        labelName="Ich brauch Betreuung für mein Kind/meine Kinder (Mindestalter 2 Jahre)"
        [(model)]="childCare"
      ></app-form-checkbox-input>
      <div *ngIf="childCare" class="advancedOptionsField">
        <mat-form-field class="full-width">
          <mat-label>Anzahl Kinder</mat-label>
          <input
            type="number"
            id="numberOfChildren"
            [(ngModel)]="numberOfChildren"
            (ngModelChange)="trimChildren()"
            min="{{ 1 }}"
            max="{{ 5 }}"
            matInput
          />
        </mat-form-field>
        <div
          *ngFor="let num of [].constructor(numberOfChildren); let i = index"
        >
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Alter Kind {{ i + 1 }}</mat-label>
            <mat-select [(ngModel)]="childrenAges[i]">
              <mat-option
                *ngFor="let childAge of constants.childrenAgeRange"
                [value]="childAge"
              >
                {{ childAge }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="specialLeave">
      <app-form-checkbox-input
        idName="specialLeave"
        labelName="Ich brauch einen Antrag für Sonderurlaub"
        [(model)]="specialLeave"
      ></app-form-checkbox-input>
      <div
        class="addressSpecialleave advancedOptionsField"
        *ngIf="specialLeave"
      >
        <app-form-text-input
          idName="contactEmployer"
          labelName="Ansprechpartner"
          [(model)]="volunteer.contactEmployer"
        ></app-form-text-input>
        <app-form-text-input
          idName="nameEmployer"
          labelName="Name Arbeitgeber"
          [(model)]="volunteer.nameEmployer"
        ></app-form-text-input>
        <app-form-text-input
          idName="addressEmployer"
          labelName="Anschrift Arbeitgeber"
          [(model)]="volunteer.addressEmployer"
        ></app-form-text-input>
      </div>
    </div>

    <div id="remarks">
      <app-form-text-input
        idName="remarks"
        labelName="Sonstige Bemerkungen"
        [(model)]="volunteer.comment"
      ></app-form-text-input>
    </div>
    <app-form-checkbox-input
      idName="agb"
      labelName="Ich habe die Mitarbeiter-Grundsätze und Hinweise gelesen und stimme diesen zu. (Link und Download siehe oben)"
      [(model)]="agb"
      [required]="true"
    ></app-form-checkbox-input>

    <p *ngIf="submitSuccess" class="submitSuccess">
      Vielen Dank für deine Anmeldung. Falls du keine Bestätigungsmail bekommen
      hast prüfe bitte deine E-Mail Adresse und schicke ggf. die Anmeldung
      erneut ab.
    </p>
    <p *ngIf="submitFailure" class="submitFailure">
      Leider ist etwas schief gelaufen. Bitte korrigiere alle Felder, und
      schicke das Formular erneut ab.
    </p>
    <div class="submit" id="submitButton">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        (click)="createVolunteer()"
        [disabled]="!agb"
      >
        Anmelden
      </button>
    </div>
  </form>
</div>
