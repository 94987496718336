<mat-form-field class="full-width">
  <mat-label>{{ labelName }}</mat-label>
  <input
    type="text"
    id="{{ idName }}"
    [(ngModel)]="model"
    (ngModelChange)="change()"
    required="{{ required }}"
    matInput
  />
</mat-form-field>
