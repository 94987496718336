<mat-form-field class="full-width">
  <mat-label>{{ labelName }}</mat-label>
  <input
    type="number"
    id="{{ idName }}"
    [(ngModel)]="model"
    (ngModelChange)="change()"
    min="{{ minValue }}"
    max="{{ maxValue }}"
    value="{{ defaultValue }}"
    required="{{ required }}"
    matInput
  />
</mat-form-field>
