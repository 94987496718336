<div class="body">
  <app-page-title title="Mitarbeitertermine"></app-page-title>
  <div class="buttons no-print">
    <a
      href="https://intern.kinderferienwoche-maulbronn.de/remote.php/dav/public-calendars/RTc5Y6BeDTg7TpAB?export"
    >
      <button mat-raised-button color="accent">Kalender abbonieren</button>
    </a>
    <a href="">
      <button (click)="print()" mat-raised-button color="accent">
        Drucken
      </button>
    </a>
  </div>
  <p *ngIf="events.length == 0" class="no-print">Termine werden geladen...</p>
  <div class="events no-print">
    <mat-card *ngFor="let event of events" class="eventCard">
      <mat-card-header>
        <mat-card-title>{{ event.title }}</mat-card-title>
        <mat-card-subtitle>Ort: {{ event.location }}</mat-card-subtitle>
        <mat-card-subtitle
          >Von:
          {{
            event.date | date : event.timeFormat : undefined : "DE"
          }}</mat-card-subtitle
        >
        <mat-card-subtitle
          >Bis:
          {{
            event.endDate | date : event.timeFormat : undefined : "DE"
          }}</mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content> {{ event.description }} </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="print-only table">
  <table mat-table [dataSource]="events" class="mat-elevation-z0">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Titel</th>
      <td mat-cell *matCellDef="let event">
        {{ event.title }} <br />
        <i>{{ event.description }}</i>
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Ort</th>
      <td mat-cell *matCellDef="let event">{{ event.location }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Beginn</th>
      <td mat-cell *matCellDef="let event">
        {{ event.date | date : event.timeFormat : undefined : "DE" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef>Ende</th>
      <td mat-cell *matCellDef="let event">
        <span *ngIf="event.timeFormat != undefined; else elseBlock">
          {{ event.endDate | date : "shortTime" : undefined : "DE" }}
        </span>
        <ng-template #elseBlock>
          {{
            event.endDate | date : event.timeFormat : undefined : "DE"
          }}</ng-template
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
