import {
  enableProdMode,
  ErrorHandler,
  APP_INITIALIZER,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { AppComponent } from './app/app.component';
import {
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';

import '@angular/common/locales/global/de';

Sentry.init({
  dsn: 'https://7ac2af2abb284c05ba3d372263d2bd8d@o4504697944276992.ingest.sentry.io/4504697950765056',
  tunnel: 'https://sentry-relay.cr4.pw/tunnel',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', 'https://signupapi.kifewo.de'],
  replaysOnErrorSampleRate: 1.0,
});
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      MatInputModule,
      MatGridListModule,
      MatCardModule,
      MatMenuModule,
      MatIconModule,
      MatButtonModule,
      MatSelectModule,
      MatRadioModule,
      FormsModule,
      MatToolbarModule,
      LayoutModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatNativeDateModule
    ),
    MatNativeDateModule,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
