import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-form-text-input',
    templateUrl: './form-text-input.component.html',
    styleUrls: ['./form-text-input.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule]
})
export class FormTextInputComponent implements OnInit {

  @Input() idName: string = ''
  @Input() labelName: string = ''
  @Input() model: string = ''
  @Input() required: boolean = false
  @Output() modelChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  change() {
    this.modelChange.emit(this.model)
  }

}
