import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { default as cal } from '../prettier/pretty'
import { JCALComponentNode, PrettyJCALComponentNode, JCALPropertyNode, PrettyJCALPropertyNode } from '../prettier/types'
import { Event } from '../models/Event';
import { environment } from 'src/environments/environment';
import { MatCardModule } from '@angular/material/card';
import { NgFor, DatePipe, NgIf } from '@angular/common';
import { PageTitleComponent } from '../page-title/page-title.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  standalone: true,
  imports: [PageTitleComponent, NgFor, MatCardModule, DatePipe, NgIf, MatButtonModule, MatTableModule]
})
export class CalendarComponent {
  @Input() events: Event[] = []
  url = environment.api_url + "calendar"

  displayedColumns = ['title', 'location', 'date', 'endDate']

  constructor(private http: HttpClient) {
    this.getEvents(http)
  }

  async getEvents(http: HttpClient) {
    http.get(this.url).subscribe(res => {
      cal.jcal2prettyJCAL(<JCALComponentNode>res).components
        .filter((v, i, obj) => v.name == "vevent")
        .forEach((v2, i2, arr2) => {
          let title = v2.properties.filter((v, i, a) => v.name == "summary")[0]
          if (title.value == "Kernteamsitzung") return
          let location = v2.properties.filter((v, i, a) => v.name == "location")[0]
          let date = v2.properties.filter((v, i, a) => v.name == "dtstart")[0]
          let endDate = v2.properties.filter((v, i, a) => v.name == "dtend")[0]
          let description = v2.properties.filter((v, i, a) => v.name == "description")[0]
          let transp = v2.properties.filter((v, i, a) => v.name == "transp")[0]
          this.events.push(new Event(title.value, location?.value, new Date(date.value), new Date(endDate.value), description?.value, transp?.value))
          this.events = this.events.filter((e, i, a) => e.date.valueOf() > new Date("01/01/" + new Date(Date.now()).getFullYear()).valueOf())
          this.events.sort((a, b) => (a.date > b.date) ? 1 : (a.date < b.date) ? -1 : 0)
        })
    })
  }

  print() {
    window.print()
    return false
  }
}
