<mat-form-field appearance="fill" class="full-width">
  <mat-label>{{ labelName }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [(ngModel)]="date"
    (ngModelChange)="change()"
    required="{{ required }}"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
