import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRange, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import 'moment/locale/de';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/

@Component({
    selector: 'app-form-date-input',
    templateUrl: './form-date-input.component.html',
    styleUrls: ['./form-date-input.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule]
})
export class FormDateInputComponent implements OnInit {
  @Input() idName: string = ''
  @Input() labelName: string = ''
  @Input() model: string = '';
  @Input() required: boolean = false
  date: Date = new Date(this.model)
  @Output() modelChange = new EventEmitter<string>();


  constructor(
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) { }

  ngOnInit(): void {
  }

  change() {
    this.modelChange.emit(this.date.toISOString())
  }

}
