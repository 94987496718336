import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule, _MatCheckboxRequiredValidatorModule } from '@angular/material/checkbox';

@Component({
    selector: 'app-form-checkbox-input',
    templateUrl: './form-checkbox-input.component.html',
    styleUrls: ['./form-checkbox-input.component.scss'],
    standalone: true,
    imports: [MatCheckboxModule, _MatCheckboxRequiredValidatorModule, FormsModule]
})
export class FormCheckboxInputComponent {
  @Input() idName: string = ''
  @Input() labelName: string = ''
  @Input() model: boolean = false
  @Input() required: boolean = false
  @Output() modelChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  change() {
    this.modelChange.emit(this.model)
  }
}
