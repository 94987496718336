import { Duty } from "./Duty"
import { DutyId } from "./DutyId"

export class FormDuty extends Duty {
    accepted: boolean

    constructor(id: number, name: string, accepted: boolean) {
        super(id, name)
        this.accepted = accepted
    }

    getDuty(): Duty {
        return new Duty(this.id, this.name)
    }

    getDutyId(): DutyId {
        return new DutyId(this.id)
    }
}

