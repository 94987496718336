<div class="footer no-print">
  <div class="footer-content">
    <div class="left footer-item">
      <a href="https://www.kinderferienwoche-maulbronn.de/impressum/"
        >Impressum</a
      >
      <a
        href="https://www.kinderferienwoche-maulbronn.de/datenschutzerklaerung/"
        >Datenschutzerklärung</a
      >
      <a
        href="https://www.kinderferienwoche-maulbronn.de/ueber-uns/spender-werden/"
        >Spendenkonto</a
      >
    </div>
    <div class="right footer-item">
      <a href="https://kifewo.de/instagram">Instagram</a>
      <a href="https://kifewo.de/youtube">Youtube</a>
    </div>
  </div>
</div>
