<div class="body">
  <app-page-title title="Mitarbeiterbereich"></app-page-title>

  <p>Willkommen!</p>
  <p>Hier findest du die wichtigesten Infos für dich als Mitarbeiter.</p>

  <a href="/anmeldung"
    ><button mat-raised-button color="accent">Zur Anmeldung</button></a
  >
  <p></p>
  <a href="/calendar"
    ><button mat-raised-button color="accent">Zu den Terminen</button></a
  >
  <p></p>
  <h2>Bescheinigung Sonderurlaub</h2>
  <p>
    Du kannst bei der Anmeldung angeben, dass du einen Antrag brauchst. Die
    benötigten Unterlagen bekommst du dann nach ein paar Tagen zugeschickt.
  </p>
</div>
