export class Event {
    title?: string = ''
    location?: string = ''
    date: Date = new Date()
    endDate: Date = new Date()
    description?: string = ''
    timeFormat?: string = "short"
    transp: string = ''

    constructor(title: string, location: string, date: Date, endDate: Date, description: string, transp: string) {
        this.title = title
        this.location = location
        this.date = date
        this.endDate = endDate
        this.description = description
        if (transp == "TRANSPARENT") {
            if (date.getDay() == (endDate.getDay() - 1) && date.getHours() == 2 && endDate.getHours() == 2) {
                this.timeFormat = undefined
                endDate.setDate(date.getDate())
            }
            this.timeFormat = undefined
        }
    }
}