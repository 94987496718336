import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { VolunteerSignUpComponent } from '../volunteer-sign-up/volunteer-sign-up.component';
import { NgIf } from '@angular/common';
import { PageTitleComponent } from '../page-title/page-title.component';

@Component({
    selector: 'app-vol-sign-up',
    templateUrl: './vol-sign-up.component.html',
    styleUrls: ['./vol-sign-up.component.scss'],
    standalone: true,
    imports: [PageTitleComponent, NgIf, VolunteerSignUpComponent]
})
export class VolSignUpComponent {
  enabled = environment.enabled;

  constructor(private route: ActivatedRoute) {
    route.queryParams
      .subscribe(params => {
        if (params["dev"] == "dev") this.enabled = true
      }
      );
  }
}
