import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PageTitleComponent } from '../page-title/page-title.component';

@Component({
    selector: 'app-welcome-page',
    templateUrl: './welcome-page.component.html',
    styleUrls: ['./welcome-page.component.scss'],
    standalone: true,
    imports: [MatButtonModule, PageTitleComponent]
})
export class WelcomePageComponent {

}
